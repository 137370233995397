import React from 'react'
import { Link } from 'gatsby'
// Styled
import { Container, Image } from './style'
import { BsArrowRightShort } from '@react-icons/all-files/bs/BsArrowRightShort'
import { MdKeyboardArrowDown } from '@react-icons/all-files/Md/MdKeyboardArrowDown'


const Hero = props => {
  return (
    <Container>
      <div className='content'>
        <h1>
          <div className='reveal-support'><span>{props.title}</span></div>
          <div className='reveal-support'><span>{props.title2}</span></div>
        </h1>
        <p>{props.text}</p>
      </div>

      <Image>
        <div className='scrollDown'>
          <div className='cicleScroll'>
            <MdKeyboardArrowDown style={{ fontSize: '50', color: 'white', height: 'initial'}}/>
          </div>
        </div>
        <div style={{ background: `url(${props.image}) no-repeat center center`, width: '100%', height: '100%', backgroundSize: 'cover' }}/>

      </Image>
    </Container>
  )
}
export default Hero
