import styled from 'styled-components'
import { color, fontSize } from '../../data/dataPatterns'


export const Container = styled.header`
  position: fixed;
  width: 10%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 997;
  .line {
    width: 1.4px;
    height:150px;
    background: ${color.primaryColor};
    margin-left: 20px;
  }
  .content {
    display: flex;
    flex-direction: column;
    img {
      width: 50px;
      height: 50px;
      margin-bottom: 20px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid ${color.primaryColor};
      cursor: pointer;
    }
  }


  @media (max-width: 768px) {
    width: 100%;
    height: initial;
    justify-content: initial;
    align-items: initial;
    flex-direction: column;
    transition: 0.3s ease-in-out;
    padding: ${({navScroll}) => (navScroll ? '15px 8%' : '40px 8%')};
    background: ${({navScroll}) => (navScroll ? 'rgba(237, 224, 212, 0.6)' : 'transparent')};
    backdrop-filter: blur(2px);
    top: 0;
    .line {
      display: none;
    }
    .content {
      flex-direction: row;
      img { margin-right: 30px; margin-bottom: 0px;}
    }
  }
`


export const Menu = styled.div`
  position: fixed;
  top: 0;
  width: 30%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${color.primaryColor};
  z-index: 999;
  transition: 0.4s ease-in-out;
  transform: ${({menu}) => (menu ? 'translateX(0)' : 'translateX(-100%)')};

  a {
    color: ${color.whiteColor};
    font-size: ${fontSize.big};
    text-decoration: none;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  button {
    position: absolute;
    top: 50px;
    right: 100px;
    background: none;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 480px) {
    width: 100%;
    button {
      right: 50px;
    }
  }
`