import { createGlobalStyle } from "styled-components";
import Marta from './marta.italic.otf'
import NexaLight from './Nexa-Light.otf'
import NexaBold from './Nexa-Bold.otf'

const FontStyle = createGlobalStyle`
  @font-face {
    font-family: Marta;
    src: url(${Marta});
  }
  @font-face {
    font-family: NexaLight;
    src: url(${NexaLight});
  }
  @font-face {
    font-family: NexaBold;
    src: url(${NexaBold});
  }

`
export default FontStyle