import styled from 'styled-components'
import { color, fontSize } from '../../data/dataPatterns'


export const CardWorkStyle = styled.div`
  max-width: 1000px;
  background: ${color.backgroundColor};
  display: flex;
  flex-direction: row;
  padding: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  margin-top: 60px;
  .info {
    margin-left: 50px;
    h1 {
      font-size: 2rem;
      color: ${color.blueColor};
      margin-bottom: 1.3rem;
    }
    p{
      width: 90%;
      text-align: left;
      line-height: 1.7rem;
      button {
        background: transparent;
        border: none;
        font-family: Marta, sans-serif;
        color: ${color.blueColor};
        font-size: 1rem;
        /* text-decoration: underline; */
        cursor: pointer;
        z-index: 100;
        animation: ${({more}) => (more ? 'FadeText 1s ease-in-out' : '')}
      }
      span {
        animation: FadeText 1s ease-in-out;
        display: ${({more}) => (more ? 'initial' : 'none')};
      }
    }
  }
  @keyframes FadeText {
    from {opacity: 0;}
    to {opacity: 1;}
  }



  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: initial;
    align-items: flex-start;
    padding: 35px 45px;
    img {
      width: 100%;
    }
    .info {
      margin: 50px 0 0;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 30px;
  }
`