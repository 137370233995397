export const color = {
  backgroundColor: '#ede0d4',
  primaryColor: '#9e0059',
  grayColor: '#7a7a7a',
  darkGrayColor: '#4e4e4e',
  blueColor: '#669bbc',
  orangeColor: '#fb5607',
  whiteColor: '#faf8f0',
}


export const fontSize = {
  title: '7rem',
  extraBig: '4.5rem',                 // 72px
  extra: '3.5rem',                 // 56px
  big: '2.5rem',                  // 40px

  extraMiddle: '1.75rem',       // 28px
  bigMiddle: '1.375rem',       // 22px
  middle: '1rem',             // 16px

  extraSmall: '0.875rem',      // 14px
  bigSmall: '0.625rem',       // 10px
  small: '0.375rem',         // 6px
}