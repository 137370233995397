import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = props => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <link rel='icon' type='image/x-icon' href='../images/logo.png'/>
      <meta name="description" content={props.description}></meta>
    </Helmet>
  )
}

export default SEO