import React from 'react'
// Style
import { CardWorkStyle } from './style'

const CardWork = (props) => {

  const [ more, setMore] = React.useState(false)

  return(
    <CardWorkStyle more={more}>
      {/* <div className={`img ${props.class}`}/> */}
      <img src={props.img} alt=''/>
      <div className='info'>
        <h1>{props.theme}</h1>
        <p>{props.description}
          <span>{props.readMore}</span>
          <button onClick={ () => setMore(!more) }>
            {more ? 'Minimizar' : '... Continue lendo'}
          </button>
        </p>
      </div>
    </CardWorkStyle>
  )
}
export default CardWork