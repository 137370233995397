import styled from 'styled-components'
import { color, fontSize } from '../../data/dataPatterns'
// Link
import BackImage from '../../images/imgHero.png'


export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  .content {
    padding: 0 15%;
    h1 {
      font-family: Marta, sans-serif;
      font-weight: 100;
      font-size: ${fontSize.title};
      color: ${color.primaryColor}
    }
    p{
      width: 60%;
      font-family: NexaBold, serif;
      font-size: ${fontSize.middle};
      color: ${color.darkGrayColor};
      margin: 1.5rem 0 3rem;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      .line { width: 150px; height: 2px; background: ${color.blueColor}; margin-right: 20px;}
      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: ${color.primaryColor};
        text-decoration: none;
        padding: 0 30px;
        border: none;
        border-radius: 100px;
        height: 35px;
        font-family: Marta, sans-serif;
        color: ${color.whiteColor};
      }
      .atend {
        margin-left: 20px;
        background: transparent;
        border: 1px solid ${color.darkGrayColor};
        color: ${color.darkGrayColor};
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: end;
    align-items: initial;
    margin-top: 120px;
    .content {
      padding: 10px 7% 60px;
    }
  }
  @media (max-width: 480px) {
    height: 100vh;
    margin-top: 70px;
    .content {
      padding-top: 0;
      h1{ font-size: 19vw }
      p { width: 80% }
      .buttons { 
        .line { display: none;}
      }
    }
  }
`

export const Image = styled.div`
  background: #000;
  height: 100%;
  width: 50%;
  .image {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .scrollDown {
    position: absolute;
    width: 0.6rem;
    height: 100%;
    background: ${color.orangeColor};
    z-index: 10;
    .cicleScroll {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background: ${color.orangeColor};
      left: -1.7rem;
      bottom: 3rem;
      svg {
        animation: ScrollEf 1.4s ease-in-out infinite;
      }
    }
  }
  @keyframes ScrollEf {
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(10px);
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    height: 40%;
    width: 100%;
    .scrollDown {display: none;}
  }
`