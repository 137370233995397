import styled from 'styled-components' 
// Data
import { color, fontSize} from '../../data/dataPatterns'


export const Container = styled.section`
  padding: 100px 13%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  img {
    width: 200px;
    margin-right: 30px;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    margin-right: 15px;

    h3 {
      font-size: ${fontSize.bigMiddle};
      margin-bottom: 15px;
      color: ${color.primaryColor};
    }
    a{
      font-size: ${fontSize.middle};
      margin-bottom:7px;
      color: ${color.darkGrayColor};
      font-weight: bolder;
      text-decoration: none;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  @media (max-width: 480px) {
    padding: 100px 7%;
    flex-direction: column;
    align-items: flex-start;
    img{
      margin: 0 0 50px 0;
    }
    .flex-column {
      margin: 0 0 30px 10px;
    }
    .content {
      flex-direction: column;
    }
  }
  
`

export const DevelopBy = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  h2 {
    width: fit-content;
    margin: 0 auto;
    font-family: 'Marta', serif;
    font-size: 1rem;
    font-weight: lighter;
    color: #303030;
    a{
      text-decoration: none;
      font-weight: bold;
      color: #fb5607;
    }
  }
`