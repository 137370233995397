import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
// styled
import { Container, Menu } from './style'
import { FiMenu } from '@react-icons/all-files/Fi/FiMenu'
import { IoIosClose } from '@react-icons/all-files/Io/IoIosClose'
import Logo from '../../images/Logo.png'


const Header = () => {

  const [navScroll, setNavScroll ] = useState(false)
  const [ menu, setMenu ] = useState(false)

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 90 ){
        setNavScroll(true)
      } else {
        setNavScroll(false)
      }
    }
    window.addEventListener('scroll', changeBackground)
  }, [])

  return (
    <>
      <Menu menu={menu}>
        <button onClick={() => setMenu(false)}>
          <IoIosClose style={{ fontSize: '4rem', color: '#faf8f0'}}/>
        </button>
        <div style={{background: 'white', width: 2, height: 400, marginRight: 40}}/>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Link to='/'>Home</Link>
          <Link to='/sobre'>Sobre mim</Link>
          <Link to='/#oquefaço'>O que faço</Link>
          <Link to='/oficinas'>Oficinas</Link>
        </div>
      </Menu> 

      <Container navScroll={navScroll}>
        <div className='content'>
          <Link to='/'>
            <img src={Logo} />
          </Link>
          <button className='toggle' onClick={() => setMenu(true)}>
            <FiMenu style={{ fontSize: '25', color: '#9e0059' }}/>
          </button>
        </div>
        <div className='line'/>
      </Container>
    </>
  )
}
export default Header

