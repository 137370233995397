import { createGlobalStyle } from 'styled-components'
// data patterns
import { color } from '../data/dataPatterns'

export const GlobalStyles = createGlobalStyle`

  * { padding: 0; margin: 0; box-sizing: border-box; }

  html, body {
    background: ${color.backgroundColor};
    font-family: NexaLight, sans-serif;
    font-size: 16px;
    scroll-behavior: smooth;
  }
`