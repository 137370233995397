import React from 'react'
import { Link } from 'gatsby'
// import { AiOutlineInstagram } from "react-icons/ai/";
// import { AiFillYoutube } from "react-icons/ai/";
// import { ImWhatsapp } from "react-icons/im/";
// import { IoLocationSharp } from "react-icons/io/";
// style
import { Container, DevelopBy } from './style'
import LogoFooter from '../../images/logo-footer.png'
// Data
import { data, social } from '../../data/dataFooter'


const Footer = () => {
  return (
    <>
      <Container>
        <img src={`${LogoFooter}`} alt='' />
        <div className='content'>
          {data.map( (data) => 
            <div className='flex-column'>
              <h3>{data.id}</h3>
              <Link to={data.Link1}>{data.option1}</Link>
              <Link to={data.Link2}>{data.option2}</Link>
              <Link to={data.Link3}>{data.option3}</Link>
              <Link to={data.Link4}>{data.option4}</Link>
            </div> 
          )}
          <div className='flex-column'>
            <h3>{social.id}</h3>
            <a href={social.link1} target='blank'>
              {/* <AiOutlineInstagram/> */}
              {social.option1}
              </a>
            <a href={social.link2} target='blank'>
              {/* <ImWhatsapp/>  */}
              {social.option2}
              </a>
            <a href={social.link3} target='blank'>
              {/* <AiFillYoutube/> */}
              {social.option3}
              </a>
            <a href={social.link4} target='blank'>
              {/* <IoLocationSharp/> */}
              {social.option4}
              </a>
          </div> 
        </div>
      </Container>
      <DevelopBy>
        <h2>Desenvolvido pela <a href='https://impactusdev.com' target='blank'>Impactusdev</a></h2>
      </DevelopBy>
    </>
  )
}

export default Footer