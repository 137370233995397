export const data = [
  {
    id: 'Páginas',
    option1: 'Home',
    Link1: '/',
    option2: 'Sobre mim',
    Link2: '/sobre',
    option3: 'O que faço?',
    Link3: '/#oquefaço',
    option4: 'Oficinas',
    Link4: '/oficinas',
  },
  {
    id: 'Serviços',
    option1: 'Artes Manuais',
    Link1: '/#oquefaço',
    option2: 'Arte educação terapêutica',
    Link2: '/#oquefaço',
    option3: 'Arteterapia',
    Link3: '/#oquefaço',
    option4: 'Oficinas',
    Link4: '/oficinas',
  },
]

export const social = {
  id: 'Entre em contato',
  option1: 'Instagram',
  link1: 'https://www.instagram.com/ateliersemente',

  option2: 'Whatsapp',
  link2: 'https://wa.me/5519982178975',

  option3: 'Youtube',
  link3: 'https://www.youtube.com/channel/UC-AJcuTa5LvnLLc3TDP_t6w',

  option4: 'Localização',
  link4: 'https://impactusdev.com',
}